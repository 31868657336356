import { useRef } from "react";
import React from "react";
import "./App.scss";
import "./AppMobile.scss";
import InfoContainer from "./components/InfoContainer/InfoContainer";
import emailjs from "@emailjs/browser";

function App() {
  let form: any = useRef();

  const sendEmail = (e: any) => {
    e.preventDefault();

    emailjs
      .sendForm("service_landing_page", "template_lww3xvu", form.current, {
        publicKey: "59L7umJbQvW-wrQ6O",
      })
      .then(
        () => {
          alert(
            "Email erfolgreich gesendet! Wir verarbeiten diese und kommen in kürze auf Sie zurück."
          );
          window.location.reload();
          console.log("SUCCESS!");
        },
        (error: any) => {
          console.log("FAILED...", error);
        }
      );
  };

  const KontaktRef = useRef<HTMLDivElement | null>(null);
  return (
    <div className="App">
      <div className="Home">
        <img
          src="assets/Logo White.png"
          alt="Logo"
          style={{ height: "200px" }}
        />
      </div>
      <div className="Leistungen">
        <div style={{ maxWidth: "650px" }}>
          <h1>WAS SIND UNSERE LEISTUNGEN?</h1>
          <p style={{ fontSize: "1.5rem", color: "#606060" }}>
            Jedes Unternehmen ist einzigartig, und so sind auch unsere Lösungen.
            Wir entwickeln...
          </p>
          <div className="Points">
            <div className="UpperPoints">
              <div className="Bullet">
                <div className="Dot"></div>
                <p>Zuverlässig</p>
              </div>
              <div className="Bullet">
                <div className="Dot"></div>
                <p>Effizient</p>
              </div>
            </div>
            <div className="LowerPoints">
              <div className="Bullet">
                <div className="Dot"></div>
                <p>Mit Erfahrung</p>
              </div>
              <div className="Bullet">
                <div className="Dot"></div>
                <p>Preiswert</p>
              </div>
            </div>
            <button
              onClick={() => {
                KontaktRef.current?.scrollIntoView({
                  behavior: "smooth",
                });
              }}
              className="Kontaktieren"
            >
              <p>Kontaktieren</p>
            </button>
          </div>
        </div>
        <div className="Info-selection">
          <InfoContainer
            align="start"
            color="#2E4057"
            title="Webseiten Entwickeln"
            description="Wir erstellen benutzerfreundliche und responsive Webseiten, die sich an Ihre individuellen Bedürfnisse anpassen und gleichzeitig modern und funktional bleiben."
            imgPath="assets/World.png"
          ></InfoContainer>
          <InfoContainer
            align="center"
            color="white"
            title="Instandhaltung"
            description="Wir kümmern uns um Updates, Fehlerbehebungen und Optimierungen, damit Sie sich auf Ihr Geschäft konzentrieren können, während wir uns um die technische Seite kümmern."
            imgPath="assets/Maintenance.png"
          ></InfoContainer>
          <InfoContainer
            align="end"
            color="white"
            title="Grafikdesign"
            description="Ob Sie ein neues Logo, Branding-Elemente oder Layout-Designs benötigen, wir liefern kreative und ansprechende Designs, die Ihre Marke hervorheben."
            imgPath="assets/Grafikdesign.png"
          ></InfoContainer>
        </div>
      </div>
      <div className="Bereiche">
        <h1>UNSERE BEREICHE</h1>
        <div className="Elements">
          <div className="Element-Div">
            <img className="Element-img" src="assets/Design.png" alt="img" />
            <p>
              Interface <br /> & Design
            </p>
          </div>
          <div className="Element-Div">
            <img className="Element-img" src="assets/Frontend.png" alt="img" />
            <p>
              Frontend- <br /> Entwicklung
            </p>
          </div>
          <div className="Element-Div">
            <img className="Element-img" src="assets/Backend.png" alt="img" />
            <p>
              Backend- <br /> Entwicklung
            </p>
          </div>
          <div className="Element-Div">
            <img
              className="Element-img"
              src="assets/App-Entwicklung.png"
              alt="img"
            />
            <p>
              App- <br /> Entwicklung
            </p>
          </div>
        </div>
      </div>
      <div ref={KontaktRef} className="Kontakt">
        <h1>Kontakt</h1>
        <form className="Kontakt-Container" ref={form} onSubmit={sendEmail}>
          <div className="Text-Container">
            <input
              className="Small-Input"
              type="email"
              name="from_email"
              placeholder="Email"
              required
            />
          </div>
          <div className="Text-Container">
            <input
              className="Small-Input"
              type="text"
              name="from_name"
              placeholder="Name"
              required
            />
          </div>
          <div className="Text-Container">
            <textarea
              className="Big-Input"
              placeholder="Text"
              name="message"
              id="message"
              required
            />
          </div>
          <div className="Button-Container">
            <button type="submit" value="Send" className="Button">
              <p>Senden</p>
            </button>
          </div>
        </form>
      </div>
      <div className="Footer">
        <img
          className="Footer-Bild"
          src="assets/FooterBG.png"
          alt="Benni&Paul"
        />
        <p className="Benni">
          BENJAMIN <br /> FRANK
        </p>
        <p className="Paul">
          PAUL <br /> ALTMANN
        </p>
      </div>
      <div className="Impressum">
        <h1>Impressum</h1>
        <div className="Elements">
          <div className="Element-Div">
            <p>
              P&B Code <br /> Paul Altmann
            </p>
          </div>
          <div className="Element-Div">
            <p>
              In der Pfad 20 <br /> 56761 Eulgem
            </p>
          </div>
          <div className="Element-Div">
            <p>
              +49 1522 4143093 <br /> pb-code@outlook.com
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
