import "./InfoContainer.scss";
import "./InfoContainerMobile.scss";

export default function InfoContainer(params: any) {
  return (
    <div
      className="Info-Container"
      style={{ alignSelf: params.align, backgroundColor: params.color }}
    >
      <div
        className="Dot"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: params.color !== "white" ? "white" : "#E8E8E8",
        }}
      >
        <img
          style={{ width: "70px", userSelect: "none", pointerEvents: "none" }}
          src={params.imgPath}
          alt="img"
        ></img>
      </div>
      <div className="Text-Container">
        <p
          style={{
            fontSize: "1.6rem",
            fontWeight: "700",
            color: params.color === "white" ? "black" : "white",
          }}
        >
          {params.title}
        </p>
        <p
          style={{
            fontSize: "1rem",
            color: params.color === "white" ? "black" : "white",
            opacity: 0.8,
            maxWidth: "90%",
          }}
        >
          {params.description}
        </p>
      </div>
    </div>
  );
}
